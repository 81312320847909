import {
    HttpClient,
    HttpErrorResponse,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
// import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
    providedIn: 'root'
})
export class RequestService implements HttpInterceptor {
    constructor(
        private http: HttpClient,
        private router: Router,
        // private modalService: NzModalService,
    ) { }

    // 基础路径
    public baseUrl = '/api';
   

    /**
     * get请求处理
     * @param url api地址
     * @param params Object
     */
    public get(url: string, params?: object): Observable<any> {
        const item: string = this.getParamsFormatter(params);
        return this.http
            .get(`${this.baseUrl}${url}${item}`)
            .pipe(map(this.extractData), catchError(this.handleError));
    }


    /**
     * post请求处理
     * @param url api地址
     * @param data object
     */
    public post(url: string, data: any): Observable<any> {
        let headers = {};
        // 登陆设置请求头(登陆使用独立的请求头)
        if (url === 'oauth/oauth-login') {
            headers = [
                { 'Content-Type': 'multipart/form-data' },
                { Accept: '*/*' }
            ];
            const formData = new FormData();
            formData.set('username', data.username);
            formData.set('password', data.password);
            data = formData;
        }

        return this.http
            .post(`${this.baseUrl}${url}`, data, { headers })
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    
   
    /**
     * put请求
     * @param url api地址
     * @param data object
     */
    public put(url: string, data = {}): Observable<any> {
        return this.http
            .put(`${this.baseUrl}${url}`, data)
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    /**
     * delete请求
     * @param url api地址
     */
    public delete(url: string): Observable<any> {
        return this.http
            .delete(`${this.baseUrl}${url}`)
            .pipe(map(this.extractData), catchError(this.handleError));
    }

    /**
     * 提取数据
     * @param res 返回结果
     */
    private extractData(res: Response) {
        const body = res;
        return body || {};
    }

    /**
     * 错误消息类
     * @param error 错误
     */
    private handleError(error: HttpErrorResponse) {
        if (error && error.status === 401) {
            // console.log(JSON.parse(error.error.msg));
            // console.log(error, '错误信息');
        }
        if (error.error instanceof ErrorEvent) {
            // console.error('An error occurred:', error.error.message);
        } else {
            // console.error(
            //   `Backend returned code ${error.status}, ` + `body was: ${error.error}`
            // );
        }
        // return
        return throwError('Something bad happened; please try again later.');
    }

    /**
     * get方式过滤自动拼接数据
     * @param val
     */
    private getParamsFormatter(val: object) {
        if (typeof val === 'object') {
            let item: string = '?';
            for (let n in val) {
                item += `${n}=${val[n]}&`;
            }
            return item.substring(0, item.length - 1);
        }
        return '';
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // const token: string = localStorage.getItem('token')
        //     ? JSON.parse(localStorage.getItem('token'))
        //     : null;
        const token = "eyJhbGciOiJIUzI1NiIsInppcCI6IkRFRiJ9.eNpcTkkKgzAUvctfRzCpMdZ7dC0_g5iiX8kAldK7N9pCocs3vyckR0hp8Bb6lkHMGnogjFMmjTu6AAxydGFI--aKtAbrCcN-iz_pyArJQAckM52Q8jyXNrOeIbSLp-J2jw163jaKS8mFYuAxHcSlk_xD3JMvfm24sbWzFSrLq0ajrLpaq4qP7XWsRSOsxO844eL-H7_eAAAA__8.aPa4ch6_5WzvLy2YZjUVtm6-S9TiRGZVyqFevM7kJ6g";
        if (token) {
            request = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + token)

            })
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    switch (event.body.code) {
                        case 200:
                            break;
                        case 401:
                            // this.OpenMessage('提示', '用户身份过期', 'login');
                            localStorage.clear();
                            break;
                        case 500:
                            //this.OpenMessage('提示', '数据错误500');
                            // this.message.info('提示，数据错误500');
                            break;
                    }
                }
                return event;
            })
        );
    }

    /**
     * 弹出信息提示
     * @param title
     * @param content
     * @param path
     * @constructor
     */
    // private OpenMessage(title: string, content: string, path?: string) {
    //     this.modalService.confirm({
    //         nzTitle: `<i>${title}?</i>`,
    //         nzContent: `<b>${content}</b>`,
    //         nzOnOk: () => {
    //             if (path) {
    //                 this.router.navigate([path]);
    //             }
    //         }
    //     });
    // }
}
