import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  isCollapsed = false;
  constructor(){
    let token="eyJhbGciOiJIUzI1NiIsInppcCI6IkRFRiJ9.eNpMjsEKgzAQRP8lZwMmpqn4Hz3LxN3QFIwSIyil_97UWuhp2Zk3O_sUmSNi7gOJTldiWZ3oxLpwEtUx-rzPXKQpUYhI--3POjMuIQ73Y1N1OTFMRwI0hlhQ3uZiWHNpbWPqwgfkj9Bctf4KjxwKT54HowGpPJM05IxsASWJPJigamdxNkeM_Hvz9QYAAP__.vPIq2Lasp01QYDiqDRgANJ7xQqx5WtTjLLoCO8sfvsU";
		localStorage.setItem('token',token)
  }
}
